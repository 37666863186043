import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { useParams } from 'react-router';

import {
    Button,
    IconButton,
    Skeleton,
    Stack,
    TextField,
    Typography,
    InputAdornment,
    useMediaQuery,
    TablePagination,
    CircularProgress,
    Card,
    CardContent,
    Menu,
    MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { createRef } from 'react';
import { useState } from 'react';

import Status from 'ui-component/buttons/Status';

import VQModal from 'ui-component/modals/VQModal';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';
import TruckSafteyFilter from 'ui-component/filters/TruckSafteyFilter';
import { useEffect } from 'react';
import axios from 'axios';
import { Box } from '@mui/system';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DQListModal from 'ui-component/modals/DQListModal';
import VQListModal from 'ui-component/modals/VQListModal';
import NewFileTypeModal from 'ui-component/modals/NewFileTypeModal';
import DeleteConfirmModal from 'ui-component/modals/DeleteConfirmModal';

const TruckSafetyComplianceForm = () => {
    const { id } = useParams();
    const { tId } = useParams();

    const tableRef = createRef();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [docName, setDocName] = useState('');
    const [checked, setChecked] = useState([]);
    const [statusChecked, setStatusChecked] = useState([]);
    const [yearFilter, setYearFilter] = useState('');
    const [driverDetails, setDriverDetails] = useState('');
    const [searchFltr, setSearchFltr] = useState('');
    const [truckQSts, setTruckQSts] = useState('');
    const [searchSts, setSearchSts] = useState('');
    const [startDoc, setStartDoc] = useState('');
    const [endDoc, setEndDoc] = useState('');
    const [startDoe, setStartDoe] = useState('');
    const [endDoe, setEndDoe] = useState('');
    const [openFile, setOpenFile] = useState(false);
    const truckName = localStorage.getItem('SafteyTruck') ? localStorage.getItem('SafteyTruck') : '';
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.company_id;
    const [docId, setDocId] = useState();

    const [selected, setSelected] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    useEffect(() => {
        if (id) {
            fetchDriver(id);
        }
    }, [id]);

    const fetchDriver = async (id) => {
        try {
            setLoading(true);
            const { data } = await axios.get(`/v1/api/users/company/driver/${id}/`, config);
            setDriverDetails(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleDeleteModalDoc = async (id) => {
        try {
            const { data } = await axios.delete(`/v1/api/fleet/safety/doc-view/${id}/`, config);
            tableRef.current.onQueryChange();
        } catch (error) {
            console.log(error);
        }
    };

    const handleCloseModal = () => {
        setOpen1(false);
    };

    const reloadData = () => {
        setOpen1(false);
        tableRef.current.onQueryChange();
    };
    const handleClickOpenModal = (id) => {
        if (id !== 0) {
            setDocId(id);
        }

        setOpen1(true);
    };
    const handleListCloseModal = () => {
        setOpen2(false);
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
    };
    const handleClickListOpenModal = (id, doc_name) => {
        if (id !== 0) {
            setDocId(id);
        }
        setDocName(doc_name);
        setOpen2(true);
    };

    const handleFileModalOpen = () => {
        setOpenFile(true);
    };

    const handleFileModalClose = () => {
        setDocId(null);

        setOpenFile(false);
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        } else {
            fetchData({ page: 0, pageSize: 10 });
        }
    };

    const handleEditOpenModal = (id, doc_name) => {
        if (id !== 0) {
            setDocId(id);
        }
        // setDocName(doc_name);
        setOpenFile(true);
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts('');
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        }

        if (newChecked1.includes('submited') && newChecked1.includes('not submited')) {
            setTruckQSts('');
        } else if (newChecked1.includes('submited')) {
            setTruckQSts('submitted');
        } else if (newChecked1.includes('not submited')) {
            setTruckQSts('not submitted');
        }
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const reset = () => {
        setStartDoc('');
        setEndDoc('');
        setStartDoe('');
        setEndDoe('');
        setTruckQSts('');
        setSearchSts('');
        setChecked([]);
        setStatusChecked([]);
        tableRef.current.onQueryChange();
    };

    function handleDownloadModalDoc(file) {
        window.open(file, '_blank');
    }

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    // ===================== Delete Handlers =====================
    const deleteFileType = async () => {
        try {
            const { data } = await axios.delete(`/v1/api/fleet/safety/doc-type-view/${docId}/`, config);
            setOpenDelete(false);
            setDocId(null);
            if (tableRef.current) {
                tableRef.current.onQueryChange();
            } else {
                fetchData({ page: 0, pageSize: 10 });
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setDocId(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setDocId(null);
    };
    // ===================== Delete Handlers =====================
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Type of VQ File</th>
                    {/* <th className="headerTd rightHidBor">VQ Status</th>

                    <th className="headerTd rightHidBor">Date </th>
                    <th className="headerTd rightHidBor">Expire Date</th>
                    <th className="headerTd rightHidBor">File Status</th>
                    <th className="headerTd rightHidBor">Descripction</th>
                    <th className="headerTd rightHidBor">File</th> */}
                    <th className="headerTd">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.stf_doc_type_name}
                </td>
                {/* <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.doc_status === 'Submitted' ? (
                        <Status text="Submitted" />
                    ) : rowData.data.doc_status === 'Not Submitted' ? (
                        <Status text="Not Submitted" />
                    ) : (
                        <Status text="Incomplete" />
                    )}
                </td>

                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.issue_date && format(new Date(rowData.data.issue_date), 'yyyy-MM-dd')}
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.expiry_date && format(new Date(rowData.data.expiry_date), 'yyyy-MM-dd')}
                </td>

                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inactive" />}
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.description}
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.sft_doc_name.split('.').pop()}
                </td> */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    {/* <IconButton onClick={() => handleClickOpenModal(rowData.data.drv_doc_id)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => handleDownloadModalDoc(rowData.data.sft_doc)}>
                        <FileDownloadIcon fontSize="small" style={{ margin: '-3px' }} />
                    </IconButton> */}
                    <IconButton onClick={() => handleEditOpenModal(rowData.data.sft_doc_type_id)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => handleClickListOpenModal(rowData.data.sft_doc_type_id, rowData.data.stf_doc_type_name)}>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                    {rowData.data.is_mand === false && (
                        <IconButton onClick={() => handleOpenDelete(rowData.data.sft_doc_type_id)}>
                            <DeleteIcon fontSize="small" color="error" style={{ margin: '-3px' }} />
                        </IconButton>
                    )}
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true); // Start loading

        try {
            const url = `/v1/api/fleet/safety/doc-type/list?page=${query.page + 1}&page_size=${
                query.pageSize
            }&keyword=${searchFltr}&company_id=${cid}&doc_type=vq&truck_id=${tId}&start_iss_date=${startDoc}&end_iss_date=${endDoc}&start_exp_date=${startDoe}&end_exp_date=${endDoe}&doc_status=${truckQSts}&is_active=${searchSts}&sft_doc_user_type=truck&search=${searchFltr}`;

            const response = await fetch(url);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: currPage - 1, pageSize: cardPerPage })
                .then((result) => {
                    setSelected(result.data || []);
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack sx={{ flexDirection: { sm: 'column', md: 'row' }, alignItems: 'baseline' }} justifyContent="flex-start" spacing={2}>
                {loading ? (
                    <Skeleton height="50px" width="250px" />
                ) : (
                    <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                        Driver:
                        <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend' }}>
                            {driverDetails && driverDetails.user.name}
                        </span>
                    </Typography>
                )}
                {loading ? (
                    <Skeleton height="50px" width="250px" />
                ) : (
                    <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                        Truck Name:
                        <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend' }}>{driverDetails && driverDetails.truck} </span>
                    </Typography>
                )}
                {loading ? (
                    <Skeleton height="50px" width="250px" />
                ) : (
                    <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                        Trailer Number:
                        <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend' }}>
                            {driverDetails && driverDetails.trailer}{' '}
                        </span>
                    </Typography>
                )}
            </Stack>

            <Stack
                sx={{ mt: 5, mb: 2, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { sm: 'baseline' } }}
                spacing={{ xs: 3, sm: 4 }}
                justifyContent="space-between"
            >
                {' '}
                <TextField
                    type="text"
                    placeholder="Search"
                    size="small"
                    value={searchFltr}
                    className="search-input"
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    sx={{ width: '300px' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* <TruckSafteyFilter
                        checked={checked}
                        handleClose={handleClose}
                        handleChecked={handleChecked}
                        yearFilter={yearFilter}
                        yearHandler={(e) => {
                            setYearFilter(e);
                            setSearchYear(e);
                        }}
                        statusChecked={statusChecked}
                        handleStatus={handleStatus}
                        startDoc={startDoc}
                        startdocHandler={(e) => setStartDoc(format(new Date(e), 'yyyy-MM-dd'))}
                        endDoc={endDoc}
                        enddocHandler={(e) => setEndDoc(format(new Date(e), 'yyyy-MM-dd'))}
                        startDoe={startDoe}
                        startdoeHandler={(e) => setStartDoe(format(new Date(e), 'yyyy-MM-dd'))}
                        endDoe={endDoe}
                        enddoeHandler={(e) => setEndDoe(format(new Date(e), 'yyyy-MM-dd'))}
                        reset={reset}
                        filterHandler={() => {
                            tableRef.current.onQueryChange();
                        }}
                    /> */}
                    <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={() => setOpenFile(true)}>
                        ADD FILE TYPE
                    </Button>
                </Box>
            </Stack>

            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected.map((driver) => (
                            <Card key={driver.driver_id} sx={{ marginBottom: '1.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px' }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        {driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}

                                        <IconButton onClick={() => handleEditOpenModal(driver.sft_doc_type_id)}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton onClick={() => handleClickListOpenModal(driver.sft_doc_type_id, driver.stf_doc_type_name)}>
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                        {driver.is_mand === false && (
                                            <IconButton onClick={() => handleOpenDelete(driver.sft_doc_type_id)}>
                                                <DeleteIcon fontSize="small" color="error" style={{ margin: '-3px' }} />
                                            </IconButton>
                                        )}

                                        {/* <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        <MenuItem onClick={() => handleClickOpenModal(driver.drv_doc_id)}>Edit</MenuItem>
                                                        <MenuItem onClick={() => handleDownloadModalDoc(driver.sft_doc)}>Download</MenuItem>
                                                        {!driver.is_mand && <MenuItem onClick={() => handleDeleteModalDoc(driver.drv_doc_id)}>Delete</MenuItem>}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState> */}
                                    </Box>

                                    <Box sx={{ clear: 'both' }} />
                                    <Typography sx={{ marginBottom: '.5rem' }}>Type of VQ File : {driver.stf_doc_type_name}</Typography>
                                    {/* <Typography sx={{ marginBottom: '.5rem' }}>
                                        VQ Status{' '}
                                        {driver.doc_status === 'Submitted' ? (
                                            <Status text="Submitted" />
                                        ) : driver.doc_status === 'Not Submitted' ? (
                                            <Status text="Not Submitted" />
                                        ) : (
                                            <Status text="Incomplete" />
                                        )}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Date: {driver.issue_date && format(new Date(driver.issue_date), 'yyyy-MM-dd')}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Expire Date: {driver.expiry_date && format(new Date(driver.expiry_date), 'yyyy-MM-dd')}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Description: {driver.description}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>File : {driver.sft_doc_name.split('.').pop()}</Typography> */}
                                </CardContent>
                            </Card>
                        ))
                    )}
                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver) => (
                            <Card
                                key={driver.driver_id}
                                sx={{ marginTop: '2rem', marginBottom: '1.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px' }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>

                                        <IconButton onClick={() => handleEditOpenModal(driver.sft_doc_type_id)}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton onClick={() => handleClickListOpenModal(driver.sft_doc_type_id, driver.stf_doc_type_name)}>
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                        {driver.is_mand === false && (
                                            <IconButton onClick={() => handleOpenDelete(driver.sft_doc_type_id)}>
                                                <DeleteIcon fontSize="small" color="error" style={{ margin: '-3px' }} />
                                            </IconButton>
                                        )}

                                        {/* <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        <MenuItem onClick={() => handleClickOpenModal(driver.drv_doc_id)}>Edit</MenuItem>
                                                        <MenuItem onClick={() => handleDownloadModalDoc(driver.sft_doc)}>Download</MenuItem>
                                                        {!driver.is_mand && <MenuItem onClick={() => handleDeleteModalDoc(driver.drv_doc_id)}>Delete</MenuItem>}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState> */}
                                    </Box>
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '1rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Type of DQ File: {driver.stf_doc_type_name}</Typography>

                                        {/* <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            DQ Status:{' '}
                                            {driver.doc_status === 'Submitted'
                                                ? 'Submitted'
                                                : driver.doc_status === 'Not Submitted'
                                                ? 'Not Submitted'
                                                : 'Incomplete'}
                                        </Typography> */}
                                    </Box>

                                    {/* <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '1rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            Date: {driver.issue_date && format(new Date(driver.issue_date), 'yyyy-MM-dd')}
                                        </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Expire Date: {driver.expiry_date && format(new Date(driver.expiry_date), 'yyyy-MM-dd')}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '1rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Description: {driver.description}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            File Type: {driver.sft_doc_name && driver.sft_doc_name.split('.').pop()}
                                        </Typography>
                                    </Box> */}
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="All Truck"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,

                        toolbar: false,
                        draggable: false,
                        columnResizable: true,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20, 50, 100]
                    }}
                />
            )}
            {/* </PageLayout> */}

            <VQModal open={open1} handleClose={handleCloseModal} id={id} tId={tId} docId={docId} reloadData={reloadData} />
            <VQListModal open={open2} handleClose={handleListCloseModal} docName={docName} reloadData={reloadData} id={id} docId={docId} tId={tId} cid={cid} />
            <NewFileTypeModal
                open={openFile}
                handleClose={handleFileModalClose}
                type="vq"
                fileId={id}
                safteyDocList={selected}
                reloadHandler={reloadData}
                id={docId}
            />
            <DeleteConfirmModal open={openDelete} handleClose={handleCloseDelete} deleteHandler={deleteFileType} />
        </>
    );
};

export default TruckSafetyComplianceForm;
